<template>
  <LayoutCliente> <Proposta /></LayoutCliente>
</template>

<script>
import LayoutCliente from "@/Layouts/LayoutCliente.vue";
import Proposta from "@/components/Cliente/Proposta.vue";
export default {
  components: { LayoutCliente, Proposta },
};
</script>

<style>
</style>