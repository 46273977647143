<template>
  <div class="bodyProposta">
    <p class="text-center titleProposta">Proposta</p>
    <div class="master-wrapper">
      <div class="circle-wrapper">
        <div class="circle-ativo">1</div>
      </div>
      <div class="circle-wrapper">
        <div class="circle">2</div>
      </div>
      <div class="circle-wrapper">
        <div class="circle">3</div>
      </div>
    </div>
    <div class="spacing">
      <img
        style="margin: 15px auto; display: block"
        src="/img/logo_pagseguro.png"
        width="250px"
        alt="PagSeguro Logo"
      />
      <p class="text-center mt-4" style="font-size: 24px; font-weight: 300">
        Estes sãos os débitos vencidos, escolha quais <br />
        deseja negociar:
      </p>

      <v-expansion-panels>
        <v-expansion-panel v-for="(item, index) in propostas" :key="index">
          <v-expansion-panel-header>
            <v-radio-group v-model="radioGroup">
              <div class="alignRadios">
                <div>
                  <v-radio :value="index" @click="setproposta(index)"></v-radio>
                </div>
                <div>
                  <span style="font-weight: bold">Contrato:</span>
                  {{ item.contrato }}
                </div>
              </div>
            </v-radio-group>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="informacaoContrato">
              <div>
                <span class="infoTexto"> Portfólio: </span>{{ item.portfolio }}
              </div>
              <div>
                <span class="infoTexto"> Dias em atraso: </span
                >{{ item.diasAtraso }}
              </div>
              <div>
                <span class="infoTexto"> Valor atualizado: </span>R$
                {{ item.propostas.valorAtualizado }}
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <p class="text-center subTituloProposta mt-9">Propostas para você</p>
      <v-radio-group v-model="radiosPagamento" mandatory>
        <div class="radios">
          <div class="alignRadios">
            <v-radio value="avista">
              <template v-slot:label>
                <div style="color: black; font-size: 17px; line-height: 24px">
                  <span>À Vista</span> <br />
                  <span style="font-weight: bold">R$ {{ aVista }} </span>
                  <br />
                  <span>no boleto</span>
                </div>
              </template>
            </v-radio>
          </div>
          <div class="negocie">
            <span>Negocie à vista</span>
          </div>
        </div>
        <div
          class="radios spacingDivider"
          v-for="(value, index) in parcelado"
          :key="index"
        >
          <div class="d-flex align-center justify-center">
            <div>
              <v-radio :value="index">
                <template v-slot:label
                  ><div class="alignRadiosParcelado">
                    <div>
                      Entrada: <br />
                      <span style="font-weight: bold"
                        >R$ {{ value.valorEntrada }}</span
                      >
                    </div>
                    <span style="font-weight: bold">
                      + {{ value.qtdeParcelas }}x {{ value.valorParcela }}</span
                    >
                    <span class="mb-6">no boleto</span>
                  </div></template
                >
              </v-radio>
            </div>
          </div>
          <div class="negocie">
            <span>{{ value.qtdeParcelas + 1 }} x </span>
          </div>
        </div>
        <div class="d-flex mt-5" style="font-size: 17px; font-weight: bold">
          <div>
            <v-radio value="contraProposta">
              <template v-slot:label
                ><div style="color: black; font-size: 17px">
                  Faça sua contra-proposta
                </div></template
              >
            </v-radio>
          </div>
        </div>
      </v-radio-group>
      <v-btn
        color="primary"
        rounded
        large
        style="margin: 0 auto; display: block"
        class="mt-6"
        width="200"
        @click="confirmacao"
        >Avançar</v-btn
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Proposta",
  data() {
    return {
      radiosPagamento: "",
      radioGroup: 0,
      propostas: null,
      aVista: null,
      parcelado: null,
      checkedAvista: "",
    };
  },
  methods: {
    confirmacao() {
      if (this.radiosPagamento == "contraProposta") {
        let index = this.radioGroup;
        let contrato = this.propostas[index].contrato;
        localStorage.setItem("contrato", contrato);
        if (this.radiosPagamento == "avista") {
          localStorage.setItem("proposta", "avista");
          this.$router.push({ name: "Contraproposta" });
        } else {
          localStorage.setItem("proposta", "parcelado");
          this.$router.push({ name: "Contraproposta" });
        }
      } else {
        let index = this.radioGroup;
        let vezes = this.radiosPagamento;
        if (this.radiosPagamento == "avista") {
          let valorAvista = this.propostas[index].propostas.avista.valor;
          let contrato = this.propostas[index].contrato;
          localStorage.setItem("valorTotal", valorAvista);
          localStorage.setItem("contrato", contrato);
          localStorage.setItem("qtdeParcelas", 1);
          localStorage.setItem("valorEntrada", 1);
          localStorage.setItem("valorParcela", valorAvista);
          localStorage.setItem("proposta", "avista");
        } else {
          let qtdeParcelas =
            this.propostas[index].propostas.parcelado[vezes].qtdeParcelas;
          let valorEntrada =
            this.propostas[index].propostas.parcelado[vezes].valorEntrada;
          let valorParcela =
            this.propostas[index].propostas.parcelado[vezes].valorParcela;
          let valorParcelado =
            this.propostas[index].propostas.parcelado[vezes].valorParcelado;
          let contrato = this.propostas[index].contrato;
          localStorage.setItem("qtdeParcelas", qtdeParcelas);
          localStorage.setItem("valorEntrada", valorEntrada);
          localStorage.setItem("valorParcela", valorParcela);
          localStorage.setItem("valorTotal", valorParcelado);
          localStorage.setItem("contrato", contrato);
          localStorage.setItem("proposta", "parcelado");
        }
        this.$router.push({ name: "Confirmacao" });
      }
    },
    setproposta(index) {
      let i = index;
      this.aVista = this.propostas[i].propostas.avista.valor;
      this.parcelado = this.propostas[i].propostas.parcelado;
    },
  },
  mounted() {
    let token = localStorage.getItem("_my_token");
    axios.get(`${this.$url}/proposta?jwt=${token}`).then((resp) => {
      this.propostas = resp.data;
      if (this.propostas.length == 0) {
        this.$router.push({ name: "PropostaEnviada" });
      }
      this.aVista = resp.data[0].propostas.avista.valor;
      this.parcelado = resp.data[0].propostas.parcelado;
    });
  },
};
</script>

<style scoped>
.bodyProposta {
  font-family: "Nunito", sans-serif;
  background-color: white;
  width: 600px;
  border-radius: 8px;
  padding-bottom: 25px;
  padding-top: 5px;
  margin-top: 30px;
}

.titleProposta {
  font-size: 22px;
  font-weight: bold;
  margin: 8px 0px;
}

.subTituloProposta {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 300;
}
.master-wrapper {
  width: 100%;
  height: 10%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 20px;
  margin-bottom: 10px;
}
.circle-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
.circle-wrapper:before,
.circle-wrapper:after {
  content: "";
  position: absolute;
  display: block;
  background-color: rgb(201, 201, 201);
  height: 1px;
  width: 100%;
}
.circle-wrapper:before {
  left: 0;
}
.circle-wrapper:after {
  right: 0;
}
.circle-ativo {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: dodgerblue;
  color: white;
  position: relative;
  z-index: 1;
  text-align: center;
  font-weight: 600;
}
.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  color: black;
  border: 1px solid rgb(201, 201, 201);
  z-index: 1;
  text-align: center;
  line-height: 23px;
}

.spacing {
  padding: 0px 25px;
}

.radios {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid grey;
}
.negocie {
  background-color: red;
  height: 45px;
  padding: 8px;
  border-radius: 8px;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
}
.negocie span {
  text-transform: uppercase;
  font-weight: bold;
}

/* Altera o tamanho do checked box */
.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: 0 auto;
}

.informacaoContrato {
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  font-size: 17px;
}
.infoTexto {
  font-weight: bold;
}

.alignRadios {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
}
.alignRadiosParcelado {
  display: flex;
  flex-direction: column;
  font-size: 17px;
  color: black;
  line-height: 24px;
}
.spacingDivider {
  margin-top: 25px;
}

@media (max-width: 600px) {
  .bodyProposta {
    width: 95vw;
    margin-top: 30px;
  }
}
</style>